import React from 'react'
import {Link} from 'gatsby'

import { Wrapper } from './wrapper'

const Section = props => {
    const { section } = props
    if (!section) return null
    
    return (
        <div className="section default">
            <Wrapper section={section} />
            <div className="ctaTagline">
                {section.ctaTagline ? section.ctaTagline : ''}
            </div>
            <div className="ctaButton">
                {section.ctaTarget ? <Link to={section.ctaTarget}>{section.ctaText}</Link> : ''}
            </div>
        </div>
    )

}

export { Section }