import React from "react";

import * as sass from "./author.module.sass"

const Author = (props) => {
    return(
        <div className={sass.author}>
            <div className={sass.imgBox}>
                {props.imageUrl ? <img src={`/images/${props.imageUrl}`} alt={props.imageAlt} /> : ''}
            </div>
            <div className={sass.name}>{props.fullName && props.fullName}</div>
            <div>{props.role && props.role}</div>
        </div>
    )
}

export { Author }