import React from 'react'
import { Link } from 'gatsby'

import * as sass from "./openPositions.module.sass"

import { Wrapper } from './wrapper'

const Section = props => {
    const { section } = props
    if(!section) return null

    const openPositions = []
    section.jobs.forEach((node) => {
        const job = node.data
        openPositions.push(
            <div className={sass.jobSection}>
                {/* <div className={sass.title}></div>
                <div className="desc">{job.description}</div>
                <div className="deliverables">{job.deliverables}</div> */}
                <Link to={`${job.path}${job.slug}`}>{job.title}</Link>
            </div>
        )
    })


    return (
        <div className="section openPositions">
            <Wrapper section={section} />
            {openPositions}
        </div>
    )
}

export { Section }