import React from 'react'
import {Link} from 'gatsby'

import { Wrapper } from './wrapper'

const Section = props => {
    const {section} = props
    if(!section) return null

    return (
        <div className="section">
            <Wrapper section={section} />
            <div className="ctaTagline">{section.ctaTagline}</div>
            <div className="cta">
                    <Link to={section.ctaTarget}>{section.ctaText}</Link>
            </div>
        </div>
    )
}

export { Section }