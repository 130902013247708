import React from "react";

import * as sass from "./compareOne.module.sass";

const Section = (props) => {
 
    return (
        <div className={sass.wrapper}>
<table>
<tr>
    <th className={sass.tableName}>Functionality</th>
    <th>Plan</th>
    <th>Track</th>
    <th>Collect</th>
    <th>Warehouse</th>
    <th>Analyze</th>
    <th>Model</th>
    <th>QA</th>
    <th>Test</th>
    {/* @todo sync */}
</tr>
<tr>
    <td className={sass.left}>Yolk</td>
    <td><img src="/images/tick.svg" alt="yes" /></td>
    <td><img src="/images/tick.svg" alt="yes" /></td>
    <td><img src="/images/tick.svg" alt="yes" /></td>
    <td><img src="/images/tick.svg" alt="yes" /></td>
    <td><img src="/images/tick.svg" alt="yes" /></td>
    <td><img src="/images/tick.svg" alt="yes" /></td>
    <td><img src="/images/tick.svg" alt="yes" /></td>
    <td><img src="/images/tick.svg" alt="yes" /></td>
</tr>
<tr>
    <td className={sass.left}>{props.section.competitor}</td>
    <td>{props.section.plan === "true" ? <img src="/images/tick.svg" alt="yes" /> : ' '}</td>
    <td>{props.section.track === "true" ? <img src="/images/tick.svg" alt="yes" /> : ' '}</td>
    <td>{props.section.collect === "true" ? <img src="/images/tick.svg" alt="yes" /> : ' '}</td>
    <td>{props.section.warehouse === "true" ? <img src="/images/tick.svg" alt="yes" /> : ' '}</td>
    <td>{props.section.analyze === "true" ? <img src="/images/tick.svg" alt="yes" /> : ' '}</td>
    <td>{props.section.model === "true" ? <img src="/images/tick.svg" alt="yes" /> : ' '}</td>
    <td>{props.section.qa === "true" ? <img src="/images/tick.svg" alt="yes" /> : ' '}</td>
    <td>{props.section.test === "true" ? <img src="/images/tick.svg" alt="yes" /> : ' '}</td>
</tr>
</table>
<table>
<tr>
    <th className={sass.tableName}>Data Quality</th>
    <th>Transparency</th>
    <th>Control</th>
    <th>Accuracy</th>
    <th>Reliability</th>
    <th>Richness</th>
</tr>
<tr>
    <td className={sass.left}>Yolk</td>
    <td>High</td>
    <td>High</td>
    <td>High</td>
    <td>High</td>
    <td>High</td>
</tr>
<tr>
    <td className={sass.left}>{props.section.competitor}</td>
    <td>{props.section.transparency}</td>
    <td>{props.section.control}</td>
    <td>{props.section.accuracy}</td>
    <td>{props.section.reliability}</td>
    <td>{props.section.richness}</td>
</tr>
</table>
        </div>
    )

}

export { Section }