import React from 'react'

import { Head } from './head'

const App = props => {
    const { htmlTitle } = props
    return (
        <React.StrictMode>
            <Head htmlTitle={htmlTitle} />
            {props.children}
        </React.StrictMode>
    )
}

export { App }