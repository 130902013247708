import React from "react";
import { Link } from "gatsby";

import "./publicHeader.sass";

const Header = (props) => {


    return (
        <div className="header">
            <div className="logoBox">
                <Link to="/">
                    <img src="/images/yolkdata-logo.png" alt="yolkdata-logo" />
                </Link>
            </div>
            <div className="nav">
                <ul>
                    <li>
                        <Link to="/">Platform</Link>
                    </li>
                    {/* <li>
                        <Link to="/platform/pricing">Pricing</Link>
                    </li> */}
                    {/* <li>
                        <a href="/docs" target="_blank">
                            Docs
                        </a>
                    </li> */}
                    {/* <li>
                        <Link to="/blog">Blog</Link>
                    </li> */}
                    <li>
                        <Link to="/company/about">Company</Link>
                    </li>
                    <li>
                        <a href="https://yolkdata.freshteam.com/jobs">We're Hiring!</a>
                    </li>
                    <li></li>
                    <li></li>
                    {/* <li></li>
                    <li></li>
                    <li></li> */}
                    <li>
                        <Link to="/login" className="secondaryCta">
                            Login
                        </Link>
                    </li>
                    <li>
                        <a href="https://airtable.com/shrnkzW00Jfxx5Nm4" target="_blank" className="primaryCta">
                            See how it works
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export { Header };