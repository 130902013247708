import React from 'react'
import { Link } from 'gatsby'

import { Wrapper } from './wrapper'

import * as sass from "./team.module.sass"

const Section = props => {
    const { section } = props
    if (!section) return null

    const teammates = []
    section.teammates.forEach((node) => {
        const mate = node.data
        teammates.push(
            <div className={sass.section}>
                <div className={sass.imgBox}>
                    { mate.imageUrl ? <img src={`/images/${mate.imageUrl}`} alt={mate.imageAlt} /> : ''}
                </div>
                <div className={sass.name}>
                    <Link to={mate.slug}>{mate.fullName}</Link>
                </div>
                <div className={sass.jobTitle}>{mate.job}</div>
                <div className={sass.bioShort}>{mate.bioShort}</div>
                <div className={sass.links}>
                    <a href={mate.linkedin}>Linkedin</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a href={mate.github}>Github</a>
                </div>           
            </div>
        )
    })


    return (
        <div className="section team">
            <Wrapper section={section} subSections={teammates} />
            
        </div>
    )
}

export { Section }