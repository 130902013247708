import React from "react";
import { Link } from "gatsby";

import { Wrapper } from "./wrapper";

import * as sass from "./compare.module.sass";

const Section = (props) => {
    const { section } = props;
    if (!section) return null;

    const competitors = {}
    section.compare.forEach((node) => {
        competitors[node.data.category] = {}
        
        competitors[node.data.category].functionality = (
            <tr>
                <td className={sass.left}><Link to={`${node.data.path}${node.data.slug}`}>{node.data.competitor}</Link></td>
                <td>{node.data.plan === "true" ? <img src="/images/tick.svg" alt="yes" /> : ' '}</td>                
                <td>{node.data.track === "true" ? <img src="/images/tick.svg" alt="yes" /> : ' '}</td>
                <td>{node.data.collect === "true" ? <img src="/images/tick.svg" alt="yes" /> : ' '}</td>
                <td>{node.data.warehouse === "true" ? <img src="/images/tick.svg" alt="yes" /> : ' '}</td>
                <td>{node.data.analyze === "true" ? <img src="/images/tick.svg" alt="yes" /> : ' '}</td>
                <td>{node.data.model === "true" ? <img src="/images/tick.svg" alt="yes" /> : ' '}</td>
                <td>{node.data.qa === "true" ? <img src="/images/tick.svg" alt="yes" /> : ' '}</td>
                <td>{node.data.test === "true" ? <img src="/images/tick.svg" alt="yes" /> : ' '}</td>
            </tr>
        )


        competitors[node.data.category].quality = (
            <tr>
                <td className={sass.left}><Link to={`${node.data.path}${node.data.slug}`}>{node.data.competitor}</Link></td>
                <td>{node.data.transparency}</td>
                <td>{node.data.control}</td>
                <td>{node.data.accuracy}</td>
                <td>{node.data.reliability}</td>
                <td>{node.data.richness}</td>
            </tr>
        )
    })

    const categories = []
    section.compare.forEach((node) => {

        const competitorsFunctionality = []
        const competitorsQuality = []
        for (const [k,v] of Object.entries(competitors)) {
            if (k === node.data.category) {
                competitorsFunctionality.push(v.functionality)
                competitorsQuality.push(v.quality)
            }
        }

        categories.push(
            <div className={sass.categorySection}>
                <div className={sass.title}>{node.data.category}</div>
                <table>
                    <tr>
                        <th className={sass.tableName}>Functionality</th>
                        <th>Plan</th>
                        <th>Track</th>
                        <th>Collect</th>
                        <th>Warehouse</th>
                        <th>Analyze</th>
                        <th>Model</th>
                        <th>QA</th>
                        <th>Test</th>
                        {/* @todo sync */}
                    </tr>
                    <tr>
                        <td className={sass.left}>Yolk</td>
                        <td><img src="/images/tick.svg" alt="yes" /></td>
                        <td><img src="/images/tick.svg" alt="yes" /></td>
                        <td><img src="/images/tick.svg" alt="yes" /></td>
                        <td><img src="/images/tick.svg" alt="yes" /></td>
                        <td><img src="/images/tick.svg" alt="yes" /></td>
                        <td><img src="/images/tick.svg" alt="yes" /></td>
                        <td><img src="/images/tick.svg" alt="yes" /></td>
                        <td><img src="/images/tick.svg" alt="yes" /></td>
                    </tr>
                    {competitorsFunctionality}
                </table>
                <table>
                    <tr>
                        <th className={sass.tableName}>Data Quality</th>
                        <th>Transparency</th>
                        <th>Control</th>
                        <th>Accuracy</th>
                        <th>Reliability</th>
                        <th>Richness</th>
                    </tr>
                    <tr>
                        <td className={sass.left}>Yolk</td>
                        <td>High</td>
                        <td>High</td>
                        <td>High</td>
                        <td>High</td>
                        <td>High</td>
                    </tr>
                    {competitorsQuality}
                </table>
            </div>
        )
    })
 
    return (
        <Wrapper section={section} subSections={categories} />
    )
}

export { Section }