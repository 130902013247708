import React from "react";

import * as sass from "./wrapper.module.sass"

const Wrapper = (props) => {
  const { section, subSections } = props;

  return (
    <div className={sass.section}>
        {section.title && <div className={sass.title}>{section.title}</div>}
        {section.tagline && <div className={sass.tagline}>{section.tagline}</div>}
        {section.body && <div className={sass.body}>{section.body}</div>}
        {section.imageUrl && (
          <div className={sass.imgBox}>
            { section.imageUrl ? <img src={section.imageUrl} alt={section.imageAlt} /> : ''}
          </div>
        )}
        {subSections}
    </div>
  );
};

export { Wrapper }
