import React from "react";
import { Link } from "gatsby";

import "./publicFooter.sass"

const Footer = (props) => {

    return(
        <div className="footer">
            <div className="right">
                <ul>
                    <li>
                        {/* <Link to="/legal/terms-of-service">Terms</Link> */}
                    </li>
                    <li>
                        {/* <Link to="/legal/privacy-policy">Privacy</Link> */}
                    </li>
                </ul>
            </div>
            <div className="left">
                &copy; Yolk, Inc.
            </div>   
        </div>
    )
}

export { Footer }