import React from "react";
import { Link } from "gatsby";

import { Wrapper } from "./wrapper";

import * as sass from "./pricingTiers.module.sass"

const Section = (props) => {
    const { section } = props;
    if (!section) return null;

    const tiers = [];
    section.pricingTiers.forEach((node) => {
        const tier = node.data;
        const features = [];
        tier.features.forEach((node) => {
            features.push(<li className={sass.feature}>{node}</li>);
        });

        tiers.push(
            <div className={sass.tier}>
                <div className={sass.name}>{tier.name}</div>
                <div className={sass.price}>{tier.price}</div>
                {/* <br /> */}
                {tier.tagline && <div className={sass.tagline}>{tier.tagline}</div>}
                <ul className={sass.features}>{features}</ul>
                <div className={sass.ctaZone}>
                    <Link to={tier.ctaTarget}>{tier.ctaText}</Link>
                </div>
            </div>
        );
    });

    return (
        <Wrapper section={section} subSections={tiers} />
    );
};

export { Section };
