import React from "react";
import { Link } from "gatsby";

import { App } from "../../app"

import { Author } from "../../elements/author"

import { PrettyDate } from "../../utils/prettyDate"

import "./masterLayout.sass"

import {
    FaqsSection,
    DefaultSection,
    CtaSection,
    TeamSection,
    PricingTiersSection,
    OpenPositionsSection,
    CompareSection,
    CompareOneSection,
} from "../../sections"

const MasterLayout = (props) => {
    
    const displaySections = () => {
        const show = [];
        props.sections && props.sections.forEach((node) => {
            const section = node.data;
            const type = section.type;
            switch (type) {
                // @todo
                // case "form":
                //     section.form_link.forEach((node) => {
                //         show.push(<Form type={node.data.name} />)
                //     })
                //     break
                case "compareOne":
                    show.push(<CompareOneSection section={section} />);
                    break
                case "compare":
                    show.push(<CompareSection section={section} />);
                    break
                case "faqs":
                    show.push(<FaqsSection section={section} />);
                    break;
                case "team":
                    show.push(<TeamSection section={section} />);
                    break;
                case "cta":
                    show.push(<CtaSection section={section} />);
                    break;
                case "pricingTiers":
                    show.push(<PricingTiersSection section={section} />);
                    break;
                case "openPositions":
                    show.push(<OpenPositionsSection section={section} />);
                    break;
                default:
                    show.push(<DefaultSection section={section} />);
                    break;
            }
        });
        return show;
    };


    return (
        <App htmlTitle={props.htmlTitle}>
            <div className="app">
                {props.header && props.header}
                <div className="content">
                    <div className="leftCol"></div>
                    <div className="rightCol">
                        {props.rightCol && props.rightCol}
                    </div>
                    <div className="centerCol">
                        {props.pageTitle && <div className="pageTitle">{props.pageTitle}</div>}
                        {props.publishDate && <div className="publishDate">{PrettyDate(props.publishDate)}</div>}
                        {props.author && <Author {...props.author} />}
                        {props.pageTagline && <div className="pageTagline">{props.pageTagline}</div>}
                        {props.pageBody && <div className="pageBody">{props.pageBody}</div>}
                        {props.pageCtaText && (
                            <div className="pageCta">
                                {props.pageCtaTagline && <div className="ctaTagline">{props.pageCtaTagline}</div>}
                                <Link to={props.pageCtaTarget}>{props.pageCtaText}</Link>
                            </div>
                        )}
                        {props.pageImageUrl && (
                            <div className="pageImage">
                                <img src={`/images/${props.pageImageUrl}`} alt={props.pageImageAlt} />
                            </div>
                        )}
                        {props.sections && <div className="sections">{displaySections()}</div>}
                    </div>
                </div>
                {props.footer && props.footer}
            </div>
        </App>
    )

}

export { MasterLayout }