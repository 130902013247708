// extracted by mini-css-extract-plugin
export const left = "pricingTiers-module--left--2RurM";
export const right = "pricingTiers-module--right--aVCex";
export const h2 = "pricingTiers-module--h2--2F8QA";
export const cta = "pricingTiers-module--cta--1kkmg";
export const indent = "pricingTiers-module--indent--1zdpw";
export const section = "pricingTiers-module--section--aMfJr";
export const tier = "pricingTiers-module--tier--2wben";
export const name = "pricingTiers-module--name--1gwmV";
export const features = "pricingTiers-module--features--3TbBX";
export const ctaZone = "pricingTiers-module--ctaZone--2qDPy";