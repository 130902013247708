import React from "react";

import { Header } from "./header"
import { Footer } from "./footer"
import { MasterLayout } from "../master";

const PublicLayout = (props) => {
    return(
        <MasterLayout
            {...props}
            header={<Header />}
            footer={<Footer />}
        />
    )
}

export { PublicLayout }