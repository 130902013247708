import React from 'react'

import { Wrapper } from './wrapper'

import * as sass from "./faqs.module.sass"

const Section = props => {
    const { section } = props
    if (!section) return null
    if(!section.faqs) return null

    const faqs = []
    section.faqs.forEach((node) => {
        const faq = node.data
        faqs.push(
            <div className={sass.faq}>
                <div className={sass.question}>{faq.question}</div>
                <div className={sass.answer}>{faq.answer}</div>
            </div>
        )
    })

    return (
        <div className="section faqs">
            <Wrapper section={section} subSections={faqs} />
        </div>
    )

}

export { Section }